import { hideSnackbar, SHOW_SNACKBAR } from 'common/actions/snackbar'

export default () => dispatch => action => {
  if (action.type === SHOW_SNACKBAR) {
    setTimeout(() => {
      dispatch(hideSnackbar())
    }, 4000)
  }

  dispatch(action)
}
