// -------------- External imports ---------------- //
import { useState } from 'react'
import PropTypes from 'prop-types'

// -------------- Material-UI imports ---------------- //
import { Modal, Paper } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'

// -------------- Internal imports ---------------- //
import styles from './MasterSystemModalContainer.module.scss'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

const MasterSystemModalContainer = props => {
  const { hideModal, contentText, title } = props

  const [open] = useState(true)

  const StyledPaper = withStyles({
    root: {
      maxHeight: '100% !important',
      'overflow-y': 'visible',
      border: '2px solid #dddddd',
      verticalAlign: 'center !important',
      borderRadius: '32px',
      backgroundColor: '#ffffff'
    }
  })(props => <Paper {...props} />)

  return (
    <Modal
      onClose={hideModal}
      open={open}
      fullWidth
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
      scroll='body'
      BackdropProps={{
        classes: {
          root: styles.backdrop
        }
      }}
      PaperComponent={StyledPaper}
    >
      <div className={styles.flexModalContainer}>
        <div className={styles.modalContainer}>
          <div className={styles.header}>
            <span>
              <InfoOutlinedIcon className={styles.infoIcon} />
            </span>
            {title}
            <IconButton
              disableRipple
              aria-label='close'
              className={styles.titleIconClose}
              onClick={hideModal}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles.content}>
            <div className={styles.contentText}>{contentText}</div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

MasterSystemModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string
}

export default MasterSystemModalContainer
