import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getSnackbarMessage, getIsSnackbarVisible } from 'common/selectors/snackbar'

import Snackbar from '@material-ui/core/Snackbar'
import styles from './SnackbarRoot.module.scss'

const SnackbarRoot = ({ message, isVisible }) => (
  <Snackbar
    message={message}
    open={isVisible}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    className={styles.component}
  />
)

SnackbarRoot.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  message: PropTypes.string
}

const mapStateToProps = state => ({
  isVisible: getIsSnackbarVisible(state),
  message: getSnackbarMessage(state)
})

const mapActionsToProps = {
  // Empty
}

export default connect(mapStateToProps, mapActionsToProps)(SnackbarRoot)
