import { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styles from './AppLayout.scss'

import CssBaseline from '@material-ui/core/CssBaseline'

import MayorLoader from 'common/components/MayorLoader'
import ModalRoot from 'modules/ModalRoot'

import { getIsLoaded as getIsUserLoaded } from 'common/selectors/user'
import { getIsSnackbarVisible, getSnackbarMessage } from 'common/selectors/snackbar'
import { getIsLoaderVisible } from 'common/selectors/loader'
import SnackbarRoot from '../../../../modules/SnackbarRoot/SnackbarRoot'

export class AppLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    isUserLoaded: PropTypes.bool.isRequired,
    isLoaderVisible: PropTypes.bool.isRequired
  }

  render() {
    const { children, isUserLoaded, isLoaderVisible } = this.props

    if (!isUserLoaded) {
      return (
        <Fragment>
          <CssBaseline />
          <MayorLoader isVisible />
        </Fragment>
      )
    }

    return (
      <Fragment>
        <CssBaseline />

        <div className={styles.component}>{children}</div>

        <MayorLoader isVisible={isLoaderVisible} />
        <SnackbarRoot />
        <ModalRoot />
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isUserLoaded: getIsUserLoaded(state),
  isLoaderVisible: getIsLoaderVisible(state),
  isSnackbarVisible: getIsSnackbarVisible(state),
  snackbarMessage: getSnackbarMessage(state)
})

const mapActionsToProps = {
  // Empty
}

export default connect(mapStateToProps, mapActionsToProps)(AppLayout)
