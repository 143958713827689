import { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Modal from 'common/components/Modal'

export default class VersionModal extends PureComponent {
  static propTypes = {
    newNextVersion: PropTypes.string.isRequired,
    system: PropTypes.object.isRequired,
    hideModal: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
  }

  handleDeclineClick = () => {
    this.props.onConfirm(this.props.system, false, this.props.newNextVersion)
  }

  handleConfirmClick = () => {
    this.props.onConfirm(this.props.system, true, this.props.newNextVersion)
  }

  render() {
    const { hideModal } = this.props

    const actions = (
      <Fragment>
        <Button onClick={this.handleDeclineClick}>Disagree</Button>
        <Button
          onClick={this.handleConfirmClick}
          color='primary'
          autoFocus
        >
          Agree
        </Button>
      </Fragment>
    )

    return (
      <Modal
        title='Warning'
        hideModal={hideModal}
        actions={actions}
      >
        <p>La nueva version de Mica será la: {this.props.newNextVersion}. Estás seguro?</p>
      </Modal>
    )
  }
}
