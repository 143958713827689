import PropTypes from 'prop-types'
import styles from './AuthLayout.module.scss'

import { OrkliLogo } from 'icons'
import { Icon } from '@material-ui/core'

const AuthLayout = ({ children }) => (
  <div className={styles.component}>
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.inbiotLogo}>
          <Icon className={styles.logo}>
            <img
              className={styles.logoImage}
              src={OrkliLogo}
              alt=''
            />
          </Icon>
        </div>
        {children}
      </div>
    </div>
  </div>
)

AuthLayout.propTypes = {
  children: PropTypes.node
}

export default AuthLayout
