import { schema } from 'normalizr'

export const system = new schema.Entity(
  'systems',
  {},
  {
    idAttribute: '_id'
  }
)

export const device = new schema.Entity(
  'device',
  {},
  {
    idAttribute: '_id'
  }
)

export const user = new schema.Entity(
  'users',
  {
    systems: [system]
  },
  {
    idAttribute: '_id'
  }
)

export const lastVersions = new schema.Entity('lastVersions', {}, {})

export const model = new schema.Entity(
  'models',
  {},
  {
    idAttribute: '_id'
  }
)
