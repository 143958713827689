import request from 'utils/request'
import { Auth } from 'aws-amplify'

export function loadUser() {
  return request('users/me', {
    method: 'GET'
  }).then(([body]) => body)
}

export function login(data) {
  return request('auth', {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export async function loginCognito(data) {
  try {
    return await Auth.signIn(data.email, data.password)
  } catch (error) {
    return error
  }
}

export async function getCognitoSession() {
  try {
    return await Promise.resolve(Auth.currentSession())
  } catch (error) {
    return false
  }
}

export async function logoutCognito() {
  try {
    await Auth.signOut()
  } catch (err) {}
}
