import PropTypes from 'prop-types'
import styles from './SystemLayout.module.scss'

const SystemLayout = props => {
  return (
    <div className={styles.component}>
      <div className={styles.content}>{props.children}</div>
    </div>
  )
}

SystemLayout.propTypes = {
  children: PropTypes.node
}

export default SystemLayout
