export const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL'
export const VERSION_MODAL = 'VERSION_MODAL'
export const VERSION_MODEL_MODAL = 'VERSION_MODEL_MODAL'
export const DOWNLOAD_SETTINGS = 'DOWNLOAD_SETTINGS'
export const TYPE_INFO = 'TYPE_INFO'
export const PROGRAM_SELECTED_MODAL = 'PROGRAM_SELECTED_MODAL'
export const PROGRAMS_MODAL = 'PROGRAMS_MODAL'
export const BYPASS_INFO = 'BYPASS_INFO'
export const FILTER_STATUS = 'FILTER_STATUS'
export const EDIT_HOLIDAY_TEMP_MODAL = 'EDIT_HOLIDAY_TEMP_MODAL'
export const EDIT_TEMP_PKOM_MODAL = 'EDIT_TEMP_PKOM_MODAL'
export const EDIT_ACS_PKOM_MODAL = 'EDIT_ACS_PKOM_MODAL'
export const MASTER_SYSTEM_MODAL_CONTAINER = 'MASTER_SYSTEM_MODAL_CONTAINER'
export const RETURN_DATE_MODAL = 'RETURN_DATE_MODAL'
export const CONDENSATION_RISK_MODAL_CONTAINER = 'CONDENSATION_RISK_MODAL_CONTAINER'
