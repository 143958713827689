import { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Modal from 'common/components/Modal'

export default class ConfirmationModal extends PureComponent {
  static propTypes = {
    systemId: PropTypes.string.isRequired,
    hideModal: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    userId: PropTypes.string,
    modalTitle: PropTypes.string.isRequired,
    modalText: PropTypes.string.isRequired
  }

  handleDeclineClick = () => {
    this.props.onConfirm(this.props.systemId, false)
  }

  handleConfirmClick = () => {
    this.props.onConfirm(this.props.systemId, true, this.props.userId)
  }

  render() {
    const { hideModal, modalTitle, modalText } = this.props

    const actions = (
      <Fragment>
        <Button onClick={this.handleDeclineClick}>Cancelar</Button>
        <Button
          onClick={this.handleConfirmClick}
          color='primary'
          autoFocus
        >
          Ok
        </Button>
      </Fragment>
    )

    return (
      <Modal
        title={modalTitle}
        hideModal={hideModal}
        actions={actions}
      >
        <p>{modalText}</p>
      </Modal>
    )
  }
}
