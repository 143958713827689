import { createReducer } from 'utils'
import { fromJS } from 'immutable'
import { SHOW_SNACKBAR, HIDE_SNACKBAR } from '../actions/snackbar'

export const initialState = fromJS({
  isVisible: false,
  message: null
})

export default createReducer(initialState, {
  [SHOW_SNACKBAR]: (state, { payload }) => {
    return state.set('isVisible', true).set('message', payload)
  },
  [HIDE_SNACKBAR]: () => initialState
})
