import request from 'utils/request'

export function loadUsers() {
  return request('systems/admin/customers', {
    method: 'GET'
  }).then(([body]) => body)
}

export function updateUserDisabled(data, userId) {
  return request(`systems/admin/customers/disable/${userId}`, {
    method: 'PUT',
    data: data
  }).then(([body]) => body)
}

export function getCognitoUser(data) {
  return request('users/me', {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function deleteSystemFromUser(userId, systemId) {
  return request(`users/${userId}/system/${systemId}`, {
    method: 'DELETE'
  })
}
