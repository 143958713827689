// ---------------- External imports ------------------ //
import { useState } from 'react'
import PropTypes from 'prop-types'
import FileSaver from 'file-saver'
import { withLocalize } from 'react-localize-redux'

// ---------------- Material-UI imports ------------------ //
import { Modal } from '@material-ui/core'

// ---------------- Internal imports ------------------ //
import DownloadSettingsForm from '../components/DownloadSettingsForm'
import { requestFile } from 'utils/request'
import { getAuthStoreData } from 'utils/authStore'

const DownloadSettingsContainer = props => {
  const [open] = useState(true)

  const { hideModal, user } = props
  const { isPremium, _id } = user.toJS()

  const handleSubmit = async data => {
    if (props.isSubmitting) return

    const systemId = props.system._id
    let startDate = new Date(data.startDate.toString())
    startDate.setHours(0)
    startDate.setMinutes(0)
    startDate.setSeconds(0)

    let endDate = new Date(data.endDate.toString())
    endDate.setHours(23)
    endDate.setMinutes(59)
    endDate.setSeconds(59)

    const submitData = {
      params: data.params,
      fileType: data.fileType,
      micaname: props.system.name,
      order: Number(data.order),
      interval: data.interval,
      base: props.system.baseStationLocation,
      tz: new window.Intl.DateTimeFormat().resolvedOptions().timeZone,
      CustomerId: _id
    }
    if (!data.weather) delete submitData.base
    if (data.fileType === 'xlsx') {
      const authStoreData = getAuthStoreData()
      await requestFile(
        `systems/` + systemId + `/` + startDate.toISOString() + `/` + endDate.toISOString(),
        {
          method: 'POST',
          data: {
            ...submitData
          },
          headers: {
            accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'content-type': 'application/json',
            Authorization: authStoreData
          }
        }
      ).then(async response => {
        const blob = await response.blob().then(blob => blob)
        const fileName = response.headers.get('filename')
        FileSaver.saveAs(blob, fileName)
      })
    } else {
      await requestFile(
        `systems/` + systemId + `/` + startDate.toISOString() + `/` + endDate.toISOString(),
        {
          method: 'POST',
          data: {
            ...submitData
          }
        }
      ).then(async response => {
        const blob = await response.blob().then(blob => blob)
        const fileName = response.headers.get('filename')
        FileSaver.saveAs(blob, fileName)
      })
    }
    hideModal()
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <DownloadSettingsForm
          {...props}
          isPremium={isPremium}
          onSubmit={handleSubmit}
        />
      </Modal>
    </div>
  )
}

DownloadSettingsContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string
}

export default withLocalize(DownloadSettingsContainer)
