import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Snackbar from '@material-ui/core/Snackbar'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = () => ({
  loader: {
    padding: 0
  }
})

const MayorLoader = ({ classes, isVisible }) => {
  const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right'
  }

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={isVisible}
      // eslint-disable-next-line react/no-children-prop
      children={
        <CircularProgress
          size={35}
          color='primary'
          variant='indeterminate'
          className={classes.loader}
        />
      }
    />
  )
}

MayorLoader.propTypes = {
  classes: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired
}

export default withStyles(styles)(MayorLoader)
