import { createAction } from 'utils'

export const SHOW_SNACKBAR = '@snackbar/SHOW_SNACKBAR'
export const HIDE_SNACKBAR = '@snackbar/HIDE_SNACKBAR'

export const constants = {
  SHOW_SNACKBAR,
  HIDE_SNACKBAR
}

export const showSnackbar = createAction(SHOW_SNACKBAR)
export const hideSnackbar = createAction(HIDE_SNACKBAR)

export default {
  showSnackbar,
  hideSnackbar
}
