import request from 'utils/request'

export function loadSystems(data) {
  return request('systems/admin/devices', {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function loadLastVersions() {
  return request('systems/admin/lastversions', {
    method: 'GET'
  }).then(([body]) => body)
}

export function loadSystem(deviceId) {
  return request(`systems/admin/devices/${deviceId}`, {
    method: 'GET'
  }).then(([body]) => body)
}

export function updateResetSystem(data, deviceId) {
  return request(`systems/admin/reset/${deviceId}`, {
    method: 'PUT',
    data: data
  }).then(([body]) => body)
}

export function getSystemMeasurementsByTypeNew(data) {
  return request(`systems/mtype`, {
    method: 'POST',
    data
  }).then(([body]) => body)
}

export function deleteSystem(id) {
  return request(`systems/admin/delsystem/system/${id}`, {
    method: 'DELETE'
  })
}

export function deleteSystemData(id) {
  return request(`systems/admin/delsystem/data/${id}`, {
    method: 'DELETE'
  })
}

export function deleteSystemFromUser(id) {
  return request(`systems/admin/delsystem/fromuser/${id}`, {
    method: 'DELETE'
  })
}
