import { useState } from 'react'
import PropTypes from 'prop-types'

// Material-UI imports
import { Button, Modal } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

// Imports from own project
import styles from './BypassInfoModalContainer.module.scss'

const BypassInfoModalContainer = props => {
  const { hideModal, translate } = props

  const [open] = useState(true)

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.centerConatiner}>
          <div className={styles.modalContainer}>
            <Typography className={styles.header}>{translate('bypass')}</Typography>
            <div className={styles.modal}>
              {translate('bypassInfo')}
              <Button
                onClick={hideModal}
                className={styles.button}
              >
                {translate('exit')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

BypassInfoModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

export default BypassInfoModalContainer
