import { useState } from 'react'
import PropTypes from 'prop-types'

// Material-UI imports
import { Button, Modal } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'
import { IconButton } from '@mui/material'

// Imports from own project
import styles from './ProgramsModalContainer.module.scss'
import { ProgramView } from 'common/components/ProgramView'
import {
  program1,
  program2,
  program3,
  program4,
  program5,
  program6,
  program7,
  program8,
  program9,
  program10
} from 'constants/ProgramModes'

const ProgramsModalContainer = props => {
  const { hideModal, translate, system } = props
  const { controlVariables } = system
  const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = controlVariables

  const [open] = useState(true)
  const [showCustomProgram, setShowCustomProgram] = useState(false)

  const programModes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  const customProgramDays = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday'
  ]

  const getProgramDay = day => {
    switch (day) {
      case 'monday':
        return monday.split('')
      case 'tuesday':
        return tuesday.split('')
      case 'wednesday':
        return wednesday.split('')
      case 'thursday':
        return thursday.split('')
      case 'friday':
        return friday.split('')
      case 'saturday':
        return saturday.split('')
      case 'sunday':
        return sunday.split('')
      default:
        return monday.split('')
    }
  }

  const getProgramModes = activeProgram => {
    switch (activeProgram) {
      case 1:
        return program1
      case 2:
        return program2
      case 3:
        return program3
      case 4:
        return program4
      case 5:
        return program5
      case 6:
        return program6
      case 7:
        return program7
      case 8:
        return program8
      case 9:
        return program9
      case 10:
        return program10
      default:
        return program1
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.modalContainer}>
          <div>
            {showCustomProgram && (
              <IconButton onClick={() => setShowCustomProgram(!showCustomProgram)}>
                <ArrowBackIosNewOutlinedIcon />
              </IconButton>
            )}
            <Typography className={styles.header}>{translate('programs.programs')}</Typography>
          </div>
          <div className={styles.modal}>
            {!showCustomProgram && (
              <div className={styles.programsWrapper}>
                {programModes.map((program, index) => {
                  return (
                    <div
                      className={styles.programWrapper}
                      key={'program-' + index}
                    >
                      <h6 className={styles.programLabel}>
                        {translate('programs.program' + program)}
                      </h6>
                      <ProgramView
                        programModes={getProgramModes(program).programModes1}
                        programViewClassName={styles.programView}
                      />
                      <ProgramView programModes={getProgramModes(program).programModes2} />
                    </div>
                  )
                })}
                <div className={styles.programWrapper}>
                  <h6 className={styles.programLabel}>{translate('programs.program11')}</h6>
                  <Button
                    startIcon={<ManageSearchOutlinedIcon />}
                    className={styles.iconButton}
                    onClick={() => setShowCustomProgram(!showCustomProgram)}
                  >
                    {translate('programs.showCustom')}
                  </Button>
                </div>
              </div>
            )}
            {showCustomProgram && (
              <div className={styles.programsWrapper}>
                {customProgramDays.map((program, index) => {
                  return (
                    <div
                      className={styles.programWrapper}
                      key={'program-' + index}
                    >
                      <h6 className={styles.programLabel}>{translate('programs.' + program)}</h6>
                      <ProgramView
                        programModes={getProgramDay(program)}
                        programViewClassName={styles.programView}
                      />
                    </div>
                  )
                })}
              </div>
            )}
            <div className={styles.buttonWrapper}>
              <Button
                className={styles.button}
                onClick={hideModal}
              >
                {translate('exit')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

ProgramsModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

export default ProgramsModalContainer
