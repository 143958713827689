import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push as pushHistory } from 'react-router-redux'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import Button from '@material-ui/core/Button'

import LogoutIcon from '@material-ui/icons/ExitToApp'

import { ReactComponent as LogoIcon } from 'icons/Logo.svg'
import * as Routes from 'constants/Routes'

const initialState = {
  open: false
}

const drawerWidth = 240

const styles = theme => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    backgroundColor: '#ed1c24',
    paddingRight: 24, // keep right padding when drawer closed
    paddingLeft: 24,
    display: 'flex',
    justifyContent: 'space-between'
  },
  logOut: {
    color: 'white',
    padding: '0px'
  },
  logOutButton: {
    padding: '0px'
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,

    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },

  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    paddingLeft: '10px',
    flexGrow: 1
  },
  logo: {
    width: '60px',
    height: '60px'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto'
  }
})

export class MainLayout extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    pushHistory: PropTypes.func.isRequired
  }

  state = initialState

  handleDrawerOpen = () => {
    this.setState({ open: true })
  }

  handleDrawerClose = () => {
    this.setState({ open: false })
  }

  redirectTo = route => {
    this.props.pushHistory(route)
  }

  handleNavItemClick = route => () => {
    this.redirectTo(route)
  }

  render() {
    const { children, classes } = this.props
    const { open } = this.state

    return (
      <div className={classes.root}>
        <AppBar
          position='absolute'
          className={cx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar
            disableGutters={!open}
            className={classes.toolbar}
          >
            <LogoIcon className={classes.logo} />

            <Button
              className={classes.logOutButton}
              onClick={this.handleNavItemClick(Routes.LOGOUT)}
            >
              <LogoutIcon className={classes.logOut} />
            </Button>
          </Toolbar>
        </AppBar>

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {children}
        </main>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  // Empty
})

const mapActionsToProps = {
  pushHistory
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(MainLayout))
