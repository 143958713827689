import { useState } from 'react'
import styles from './EditHolidayTempModal.module.scss'
// Material-UI imports
import { Modal } from '@material-ui/core'
import { leftInputArrowIcon, rightInputArrowIcon, submitButtonCheck } from 'icons'

const EditHolidayTempModal = props => {
  const { hideModal, translate, updatePkomClimaVacation, system } = props

  const [open] = useState(true)
  const { controlPkomOptions } = system
  const { clima } = controlPkomOptions || {}
  const { consignTempVacation, vacationDate } = clima || {}
  const [vacationTemp, setVacationTemp] = useState(consignTempVacation || 17)
  const [vacationReturn, setVacationReturn] = useState(vacationDate || '')
  const handleForm = e => {
    e.preventDefault()
    updatePkomClimaVacation({
      _id: system._id,
      vacationDate: vacationReturn,
      consignTempVacation: vacationTemp
    })
    hideModal()
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.centered}>
          <div className={styles.modal}>
            <div className={styles.form_block}>
              <span>Fecha de regreso</span>
              <input
                className={styles.dateInput}
                type='date'
                value={vacationReturn}
                onChange={e => setVacationReturn(e.target.value)}
              />
            </div>
            <form>
              <div className={styles.form_block}>
                <span>Temperatura de consigna</span>

                <div
                  className={styles.icon_wrapper}
                  onClick={() => {
                    setVacationTemp(prev => prev - 1)
                  }}
                >
                  <img
                    src={leftInputArrowIcon}
                    alt='Temperature DOWN'
                  />
                </div>
                <input
                  className={styles.tempInput}
                  value={vacationTemp}
                  onChange={e => setVacationTemp(e.target.value)}
                />
                <div
                  className={styles.icon_wrapper}
                  onClick={() => {
                    setVacationTemp(prev => prev + 1)
                  }}
                >
                  <img
                    src={rightInputArrowIcon}
                    alt='Temperature UP'
                  />
                </div>
              </div>
              <div className={styles.alignSubmit}>
                <button
                  className={styles.submitButton}
                  onClick={handleForm}
                >
                  <img src={submitButtonCheck} />
                  {translate('applyChanges')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default EditHolidayTempModal
