import { createAction, createRequestTypes, createRequestAction } from 'utils'

export const LOAD_SYSTEMS = '@system/LOAD_SYSTEMS'
export const LOAD_SYSTEM = '@system/LOAD_SYSTEM'
export const POLL_SYSTEMS_START = '@system/POLL_SYSTEMS_START'
export const POLL_SYSTEMS_STOP = '@system/POLL_SYSTEMS_STOP'
export const LOAD_LAST_VERSIONS = '@system/LOAD_LAST_VERSIONS'
export const DELETE_SYSTEM = '@system/DELETE_SYSTEM'
export const DELETE_SYSTEM_DATA = '@system/DELETE_SYSTEM_DATA'
export const DELETE_SYSTEM_FROM_USER = '@system/DELETE_SYSTEM_FROM_USER'

export const LOAD_SYSTEMS_REQUEST = createRequestTypes('system/LOAD_SYSTEMS_REQUEST')
export const POLL_SYSTEMS_REQUEST = createRequestTypes('system/POLL_SYSTEMS_REQUEST')
export const LOAD_SYSTEM_REQUEST = createRequestTypes('system/LOAD_SYSTEM_REQUEST')
export const LOAD_LAST_VERSIONS_REQUEST = createRequestTypes('system/LOAD_LAST_VERSIONS_REQUEST')
export const DELETE_SYSTEM_REQUEST = createRequestTypes('system/DELETE_SYSTEM_REQUEST')
export const DELETE_SYSTEM_DATA_REQUEST = createRequestTypes('system/DELETE_SYSTEM_DATA_REQUEST')
export const DELETE_SYSTEM_FROM_USER_REQUEST = createRequestTypes(
  'system/DELETE_SYSTEM_FROM_USER_REQUEST'
)

export const constants = {
  LOAD_SYSTEMS,
  LOAD_SYSTEM,
  POLL_SYSTEMS_START,
  POLL_SYSTEMS_STOP,
  LOAD_LAST_VERSIONS,
  DELETE_SYSTEM,
  DELETE_SYSTEM_DATA,
  DELETE_SYSTEM_FROM_USER,

  LOAD_SYSTEMS_REQUEST,
  LOAD_SYSTEM_REQUEST,
  POLL_SYSTEMS_REQUEST,
  LOAD_LAST_VERSIONS_REQUEST,
  DELETE_SYSTEM_REQUEST,
  DELETE_SYSTEM_DATA_REQUEST,
  DELETE_SYSTEM_FROM_USER_REQUEST
}

export const loadSystems = createAction(LOAD_SYSTEMS)
export const loadSystem = createAction(LOAD_SYSTEM)
export const pollSystemsStart = createAction(POLL_SYSTEMS_START)
export const pollSystemsStop = createAction(POLL_SYSTEMS_STOP)
export const loadLastVersions = createAction(LOAD_LAST_VERSIONS)
export const deleteSystem = createAction(DELETE_SYSTEM)
export const deleteSystemData = createAction(DELETE_SYSTEM_DATA)
export const deleteSystemFromUser = createAction(DELETE_SYSTEM_FROM_USER)

export const loadSystemsRequest = createRequestAction(LOAD_SYSTEMS_REQUEST)
export const pollSystemsRequest = createRequestAction(POLL_SYSTEMS_REQUEST)
export const loadSystemRequest = createRequestAction(LOAD_SYSTEM_REQUEST)
export const loadLastVersionsRequest = createRequestAction(LOAD_LAST_VERSIONS_REQUEST)
export const deleteSystemRequest = createRequestAction(DELETE_SYSTEM_REQUEST)
export const deleteSystemDataRequest = createRequestAction(DELETE_SYSTEM_DATA_REQUEST)
export const deleteSystemFromUserRequest = createRequestAction(DELETE_SYSTEM_FROM_USER_REQUEST)

export default {
  loadSystems,
  loadSystem,
  pollSystemsStart,
  pollSystemsStop,
  loadLastVersions,
  deleteSystem,
  deleteSystemData,
  deleteSystemFromUser,

  loadSystemsRequest,
  loadSystemRequest,
  pollSystemsRequest,
  loadLastVersionsRequest,
  deleteSystemRequest,
  deleteSystemDataRequest,
  deleteSystemFromUserRequest
}
