import styles from './SmallToggleButton.module.scss'
import cx from 'classnames'
export const SmallToggleButton = ({ onChange, checked, disabled }) => {
  return (
    <div
      className={styles.button_container}
      onClick={e => e.stopPropagation()}
    >
      <label className={styles.switch}>
        <input
          type='checkbox'
          disabled={disabled}
          onChange={e => onChange(e.target.checked)}
          checked={checked}
        />
        <span className={cx(styles.slider, styles.round)} />
      </label>
    </div>
  )
}
