import { useState } from 'react'
import styles from './EditTempPkomModal.module.scss'
// Material-UI imports
import { Modal } from '@material-ui/core'
import { sunSmallIcon, iceSmallIcon } from 'icons'
const EditTempPkomModal = props => {
  const { hideModal, translate, system } = props
  const { controlPkom } = system
  const { clima } = controlPkom
  const { consignTemp, consignTempCooling } = clima

  const [refreshingTemp, setRefreshingTem] = useState(consignTempCooling || 0)
  const [heatingTemp, setHeatingTemp] = useState(consignTemp || 0)

  const [open] = useState(true)

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.centered}>
          <div className={styles.modal}>
            <span className={styles.title}>{translate('temperatureControl')}</span>
            <div className={styles.form_titles}>
              <span>{translate('mode')}</span>
              <span>{translate('temperatureControl')}</span>
            </div>
            <form>
              <div className={styles.form_block}>
                <div className={styles.aligned}>
                  <div className={styles.small_icon_wrapper}>
                    <img
                      src={sunSmallIcon}
                      alt={translate('refreshingTemp')}
                    />
                  </div>
                  <span>{translate('refreshingTemp')}</span>
                </div>
                <div className={styles.aligned}>
                  <input
                    className={styles.tempInput}
                    value={refreshingTemp}
                    disabled
                    onChange={e => setRefreshingTem(e.target.value)}
                  />
                </div>
              </div>
              <div className={styles.form_block}>
                <div className={styles.aligned}>
                  <div className={styles.small_icon_wrapper}>
                    <img
                      src={iceSmallIcon}
                      alt={translate('heatingTemp')}
                    />
                  </div>
                  <span>{translate('heatingTemp')}</span>
                </div>
                <div className={styles.aligned}>
                  <input
                    className={styles.tempInput}
                    value={heatingTemp}
                    disabled
                    onChange={e => setHeatingTemp(e.target.value)}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default EditTempPkomModal
