import { useState } from 'react'
import PropTypes from 'prop-types'

// Material-UI imports
import { Button, Modal } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

// Imports from own project
import styles from './FilterStatusInfoModalContainer.module.scss'
import colors from 'styles/_variables.module.scss'
import CircleIcon from '@mui/icons-material/Circle'

const FilterStatusInfoModalContainer = props => {
  const { hideModal, translate, filterTime } = props

  const [open] = useState(true)

  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.centerConatiner}>
          <div className={styles.modalContainer}>
            <Typography className={styles.header}>{translate('filterStatusShort')}</Typography>
            <div className={styles.modal}>
              <div className={styles.filterText}>
                <span className={styles.filterDaysText}>{filterTime}</span>
                {translate('filterInfo')}
              </div>
              <div className={styles.filterIndicatorWrapper}>
                <div className={styles.filterIndicatorRow}>
                  <CircleIcon
                    className={styles.filterIndicator}
                    style={{ color: colors.colorSuccess }}
                  />
                  {translate('filterGood')}
                </div>
                <div className={styles.filterIndicatorRow}>
                  <CircleIcon
                    className={styles.filterIndicator}
                    style={{ color: colors.colorWarning }}
                  />
                  {translate('filterMedium')}
                </div>
                <div className={styles.filterIndicatorRow}>
                  <CircleIcon
                    className={styles.filterIndicator}
                    style={{ color: colors.colorError }}
                  />
                  {translate('filterBad')}
                </div>
              </div>
              <Button
                onClick={hideModal}
                className={styles.button}
              >
                {translate('exit')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

FilterStatusInfoModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

export default FilterStatusInfoModalContainer
