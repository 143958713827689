import { API_URL } from 'config'

const defaultHeaders = {
  accept: 'application/json',
  'content-type': 'application/json'
}

export function setTokenHeader(value) {
  defaultHeaders['Authorization'] = value
}

export function clearTokenHeader() {
  delete defaultHeaders['Authorization']
}

export default function request(path, args = {}) {
  const url = `${API_URL}/${path}`

  const options = {
    headers: args.headers || defaultHeaders,
    method: args.method || 'GET'
  }

  if (args.data) {
    options.body = JSON.stringify(args.data)
    options.headers['content-type'] = 'application/json'
  }
  return fetch(url, options)
    .then(res => {
      if (res.status >= 200 && res.status < 300) {
        return res
      } else if (res.status === 401) {
        throw new Error('Unauthorized')
      } else if (res.status === 403) {
        throw new Error('Forbidden') // Handle 403 specifically
      } else {
        throw new Error(res.statusText) // Generic error for other codes
      }
    })
    .then(res => {
      if (res.status === 204) {
        return res
      }

      return res.json().then(body => [body, res])
    })
}

export function requestFile(path, args = {}) {
  const url = `${API_URL}/${path}`

  const options = {
    headers: args.headers || defaultHeaders,
    method: args.method || 'GET'
  }

  if (args.data) {
    options.body = JSON.stringify(args.data)
    options.headers['content-type'] = 'application/json'
  }
  return fetch(url, options).then(res => {
    if (res.status >= 200 && res.status < 300) {
      return res
    }
  })
}
