import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import withMobileDialog from '@material-ui/core/withMobileDialog'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'

import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
  content: {
    minWidth: '550px',
    width: 'auto'
  },
  dialogHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItem: 'center'
  },
  clearIcon: {
    marginLeft: 'auto',
    alignSelf: 'center',
    marginRight: theme.spacing.unit * 1
  }
})

export class Modal extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    hideModal: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    actions: PropTypes.node
  }

  onClose = () => {
    this.props.hideModal()
  }

  render() {
    const { children, classes, fullScreen, title, actions } = this.props

    return (
      <Dialog
        open
        fullScreen={fullScreen}
        onClose={this.onClose}
      >
        <div className={classes.dialogHeader}>
          <DialogTitle className={classes.dialogHeader}>{title}</DialogTitle>

          {fullScreen && (
            <IconButton
              className={classes.clearIcon}
              onClick={this.onClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <DialogContent className={classes.content}>{children}</DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </Dialog>
    )
  }
}

export default withMobileDialog()(withStyles(styles)(Modal))
