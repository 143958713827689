import { Map } from 'immutable'
import * as UserRoles from 'constants/UserRoles'

export const getIsAdmin = user => {
  if (!user) return false

  if (user instanceof Map) {
    return user.get('role') === UserRoles.ADMIN
  }

  return user.role === UserRoles.ADMIN
}
