import { useState } from 'react'
import styles from './EditAcsPkomModal.module.scss'
// Material-UI imports
import { Modal } from '@material-ui/core'
import { leftInputArrowIcon, rightInputArrowIcon, submitButtonCheck } from 'icons'
import { SmallToggleButton } from 'common/components/Buttons/SmallToggleButton/SmallToggleButton'

const EditAcsPkomModal = props => {
  const { hideModal, translate, updatePkomACS, system } = props
  const { controlPkom } = system
  const { ACS } = controlPkom || {}
  const { consignTemp, electricalResistance } = ACS || {}

  const [open] = useState(true)
  const [active, setActive] = useState(electricalResistance)

  const [actualTemp, setActualTemp] = useState(consignTemp || 19)

  const handleActiveResistance = newValue => {
    setActive(newValue)
  }
  const handleSubmitModal = event => {
    event.preventDefault()
    updatePkomACS({ _id: system._id, consignTemp: actualTemp, electricalResistance: active })
    hideModal()
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={hideModal}
        arial-labelledby='simple-modal-title'
        arial-desccribedby='simple-modal-description'
      >
        <div className={styles.centered}>
          <div className={styles.modal}>
            <form>
              <div className={styles.modal_title}>
                <span>{translate('temperatureControlACS')}</span>
              </div>
              <div className={styles.form_block}>
                <span>{translate('temperatureControlACS')}</span>

                <div
                  className={styles.icon_wrapper}
                  onClick={() => setActualTemp(prev => prev - 1)}
                >
                  <img
                    src={leftInputArrowIcon}
                    alt='Temperature DOWN'
                  />
                </div>
                <input
                  className={styles.tempInput}
                  value={actualTemp}
                  onChange={e => {
                    setActualTemp(e.target.value)
                  }}
                />
                <div
                  className={styles.icon_wrapper}
                  onClick={() => setActualTemp(prev => prev + 1)}
                >
                  <img
                    src={rightInputArrowIcon}
                    alt='Temperature UP'
                  />
                </div>
              </div>
              <div className={styles.form_block}>
                <span className={styles.form_block_text}>
                  {translate('activateElectricalResistanceACS')}
                </span>
                <SmallToggleButton
                  checked={active}
                  onChange={newValue => handleActiveResistance(newValue)}
                />
              </div>
              <div className={styles.alignSubmit}>
                <button
                  className={styles.submitButton}
                  onClick={handleSubmitModal}
                >
                  <img src={submitButtonCheck} />
                  {translate('applyChanges')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default EditAcsPkomModal
