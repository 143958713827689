import { createAction, createRequestTypes, createRequestAction } from 'utils'

export const AUTH_USER = '@auth/AUTH_USER'
export const LOGIN = '@auth/LOGIN'
export const LOGOUT = '@auth/LOGOUT'

export const AUTH_USER_REQUEST = createRequestTypes('@auth/AUTH_USER_REQUEST')
export const LOGIN_REQUEST = createRequestTypes('@auth/LOGIN_REQUEST')

export const constants = {
  AUTH_USER,
  LOGIN,
  LOGOUT,

  AUTH_USER_REQUEST,
  LOGIN_REQUEST
}

export const authUser = createAction(AUTH_USER)
export const logout = createAction(LOGOUT)
export const login = createAction(LOGIN)

export const authUserRequest = createRequestAction(AUTH_USER_REQUEST)
export const loginRequest = createRequestAction(LOGIN_REQUEST)

export default {
  authUser,
  login,
  logout,

  authUserRequest,
  loginRequest
}
