import { takeLatest, call, put, all, take, fork, cancel, cancelled } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { delay } from 'redux-saga'
import { push as pushHistory } from 'react-router-redux'

import actions, { constants } from 'common/actions/system'
import snackbarActions from 'common/actions/snackbar'

import * as schemas from 'schemas'
import * as api from 'api/system'
import * as Strings from 'constants/Strings'
import * as Routes from 'constants/Routes'

const POLLING_DELAY = 600000 // 10 minute
let pollSystemsTask = null

export function* onLoadSystems({ payload: data }) {
  yield put(actions.loadSystemsRequest.start())
  try {
    let { systems } = yield call(api.loadSystems, data)
    const norm = yield call(normalize, systems, [schemas.system])
    yield put(actions.loadSystemsRequest.success(norm))
    return systems
  } catch (err) {
    yield put(actions.loadSystemsRequest.failure(err))
    yield put(snackbarActions.showSnackbar(Strings.WHOOPS))
    yield put(pushHistory(Routes.LOGIN))
  }
}

export function* onPollSystems() {
  try {
    while (true) {
      yield put(actions.pollSystemsRequest.start())
      try {
        const { systems } = yield call(api.loadSystems)
        const norm = yield call(normalize, systems, [schemas.system])

        yield put(actions.pollSystemsRequest.success(norm))
        yield delay(POLLING_DELAY)
      } catch (err) {
        // Stop polling task when error
        yield put(actions.pollSystemsRequest.failure(err))
        yield put(snackbarActions.showSnackbar('snackbar.whoops'))
        yield put(actions.pollSystemsStop())
      }
    }
  } finally {
    if (yield cancelled()) {
    }
  }
}

function* watchPollSystems() {
  while (true) {
    yield take(constants.POLL_SYSTEMS_START)

    // Start polling task
    pollSystemsTask = yield fork(onPollSystems)

    // Wait until POLL_SYSTEM_STOP action
    yield take(constants.POLL_SYSTEMS_STOP)

    // Cancel polling task
    yield cancel(pollSystemsTask)
  }
}

export function* onLoadLastVersions() {
  yield put(actions.loadLastVersionsRequest.start())

  try {
    const { lastVersions } = yield call(api.loadLastVersions)
    const norm = yield call(normalize, lastVersions, schemas.lastVersions)

    yield put(actions.loadLastVersionsRequest.success(norm))
  } catch (err) {
    yield put(actions.loadLastVersionsRequest.failure(err))
    yield put(snackbarActions.showSnackbar(Strings.WHOOPS))
    yield put(pushHistory(Routes.LOGIN))
  }
}

export function* onLoadSystem({ payload: deviceId }) {
  yield put(actions.loadSystemRequest.start())

  try {
    const { system } = yield call(api.loadSystem, deviceId)
    const norm = yield call(normalize, system, schemas.device)

    yield put(actions.loadSystemRequest.success(norm))
  } catch (err) {
    yield put(actions.loadSystemRequest.failure(err))
    yield put(snackbarActions.showSnackbar(Strings.WHOOPS))
  }
}

export function* onDeleteSystem({ payload: id }) {
  yield put(actions.deleteSystemRequest.start())

  try {
    yield call(api.deleteSystem, id)

    yield put(actions.deleteSystemRequest.success({ id }))
    yield put(snackbarActions.showSnackbar('Dispositivo eliminado con éxito'))
  } catch (err) {
    yield put(actions.deleteSystemRequest.failure(err))
    yield put(snackbarActions.showSnackbar(Strings.WHOOPS))
  }
}

export function* onDeleteSystemData({ payload: id }) {
  yield put(actions.deleteSystemDataRequest.start())

  try {
    yield call(api.deleteSystemData, id)
    yield put(actions.deleteSystemDataRequest.success())
    yield put(snackbarActions.showSnackbar('Datos de dispositivo eliminados con éxito'))
  } catch (err) {
    yield put(actions.deleteSystemDataRequest.failure(err))
    yield put(snackbarActions.showSnackbar(Strings.WHOOPS))
  }
}

export function* onDeleteSystemFromUser({ payload: data }) {
  yield put(actions.deleteSystemFromUserRequest.start())

  try {
    yield call(api.deleteSystemFromUser, data.id)

    yield put(actions.deleteSystemFromUserRequest.success({ data }))
    yield put(snackbarActions.showSnackbar('Dispositivo desasociado de cuenta con éxito'))
  } catch (err) {
    yield put(actions.deleteSystemFromUserRequest.failure(err))
    yield put(snackbarActions.showSnackbar(Strings.WHOOPS))
  }
}

export default function* watchSystem() {
  yield fork(watchPollSystems)
  yield all([
    takeLatest(constants.LOAD_SYSTEMS, onLoadSystems),
    takeLatest(constants.LOAD_SYSTEM, onLoadSystem),
    takeLatest(constants.LOAD_LAST_VERSIONS, onLoadLastVersions),
    takeLatest(constants.DELETE_SYSTEM, onDeleteSystem),
    takeLatest(constants.DELETE_SYSTEM_DATA, onDeleteSystemData),
    takeLatest(constants.DELETE_SYSTEM_FROM_USER, onDeleteSystemFromUser)
  ])
}
