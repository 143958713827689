import { fromJS } from 'immutable'
import { createReducer } from 'utils'

import { AUTH_USER_REQUEST, LOGIN_REQUEST, LOGOUT } from 'common/actions/auth'

import {
  LOAD_SYSTEMS_REQUEST,
  LOAD_SYSTEM_REQUEST,
  LOAD_LAST_VERSIONS_REQUEST,
  POLL_SYSTEMS_REQUEST
} from 'common/actions/system'

import { LOAD_USERS_REQUEST, UPDATE_USER_DISABLED_REQUEST } from 'common/actions/user'

export const initialState = fromJS({
  users: {},
  systems: {},
  lastVersions: {},
  models: {},
  device: {}
})

const mergeEntities = (state, { payload }) => {
  return state.withMutations(state =>
    Object.keys(payload.entities).reduce(
      (_state, entity) => _state.mergeDeepIn([entity], payload.entities[entity]),
      state
    )
  )
}

const replaceEntities = (state, { payload }) => {
  let immutableMap
  if (payload.entities.systems) {
    immutableMap = fromJS(payload.entities.systems)
  } else {
    immutableMap = fromJS([])
  }

  return state.setIn(['systems'], immutableMap)
}

export default createReducer(initialState, {
  // User actions
  [LOGOUT]: () => initialState,
  [AUTH_USER_REQUEST.SUCCESS]: mergeEntities,
  [LOGIN_REQUEST.SUCCESS]: mergeEntities,

  // System actions
  [LOAD_SYSTEMS_REQUEST.SUCCESS]: replaceEntities,
  [LOAD_LAST_VERSIONS_REQUEST.SUCCESS]: mergeEntities,
  [POLL_SYSTEMS_REQUEST.SUCCESS]: mergeEntities,
  [LOAD_SYSTEM_REQUEST.SUCCESS]: mergeEntities,

  // User actions
  [LOAD_USERS_REQUEST.SUCCESS]: mergeEntities,
  [UPDATE_USER_DISABLED_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    return state.updateIn(['users'], users =>
      users.map(u => {
        if (u.get('_id') !== data.customerId) return u
        return u.set('disable', data.data.disabled)
      })
    )
  }
})
