import { takeLatest, call, put, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { push as pushHistory } from 'react-router-redux'

import actions, { constants } from 'common/actions/user'
import snackbarActions from 'common/actions/snackbar'

import * as schemas from 'schemas'
import * as api from 'api/user'
import * as Strings from 'constants/Strings'
import * as Routes from 'constants/Routes'

export function* onLoadUsers() {
  yield put(actions.loadUsersRequest.start())

  try {
    const { users } = yield call(api.loadUsers)
    const norm = yield call(normalize, users, [schemas.user])
    yield put(actions.loadUsersRequest.success(norm))
  } catch (err) {
    yield put(actions.loadUsersRequest.failure(err))
    yield put(snackbarActions.showSnackbar(Strings.WHOOPS))
    yield put(pushHistory(Routes.LOGIN))
  }
}

export function* onUpdateUserDisabled({ payload: data }) {
  yield put(actions.updateUserDisabledRequest.start())
  try {
    yield call(api.updateUserDisabled, data.data, data.customerId)

    yield put(actions.updateUserDisabledRequest.success({ data }))
  } catch (err) {
    yield put(actions.updateUserDisabledRequest.failure(err))
    yield put(snackbarActions.showSnackbar(Strings.WHOOPS))
  }
}

export default function* watchUser() {
  yield all([
    takeLatest(constants.LOAD_USERS, onLoadUsers),
    takeLatest(constants.UPDATE_USER_DISABLED, onUpdateUserDisabled)
  ])
}
