// -------------- External imports ---------------- //
import { useState } from 'react'
import PropTypes from 'prop-types'

// -------------- Material-UI imports ---------------- //
import { Modal, Paper } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

// -------------- Internal imports ---------------- //
import styles from './CondensationRiskModalContainer.module.scss'

const CondensationRiskModalContainer = props => {
  const { hideModal, translate, contentText, title, logo } = props

  const [open] = useState(true)

  let titleName

  if (title) {
    titleName = title
  } else {
    titleName = translate('warning')
  }

  const StyledPaper = withStyles({
    root: {
      maxHeight: '100% !important',
      'overflow-y': 'visible',
      border: '2px solid #dddddd',
      verticalAlign: 'center !important',
      borderRadius: '32px',
      backgroundColor: '#ffffff'
    }
  })(props => <Paper {...props} />)

  return (
    <Modal
      onClose={hideModal}
      open={open}
      fullWidth
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
      scroll='body'
      BackdropProps={{
        classes: {
          root: styles.backdrop
        }
      }}
      PaperComponent={StyledPaper}
    >
      <div className={styles.flexModalContainer}>
        <div className={styles.modalContainer}>
          {logo && <div>{logo}</div>}
          <div className={styles.header}>{titleName}</div>
          <div className={styles.content}>
            <div className={styles.contentText}>{contentText}</div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

CondensationRiskModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string
}

export default CondensationRiskModalContainer
