import { createAction, createRequestTypes, createRequestAction } from 'utils'

export const LOAD_USERS = '@user/LOAD_USERS'
export const UPDATE_USER_DISABLED = '@user/UPDATE_USER_DISABLED'

export const LOAD_USERS_REQUEST = createRequestTypes('user/LOAD_USERS_REQUEST')
export const UPDATE_USER_DISABLED_REQUEST = createRequestTypes('@user/UPDATE_USER_DISABLED_REQUEST')

export const constants = {
  LOAD_USERS,
  UPDATE_USER_DISABLED,

  LOAD_USERS_REQUEST,
  UPDATE_USER_DISABLED_REQUEST
}

export const loadUsers = createAction(LOAD_USERS)
export const updateUserDisabled = createAction(UPDATE_USER_DISABLED)

export const loadUsersRequest = createRequestAction(LOAD_USERS_REQUEST)
export const updateUserDisabledRequest = createRequestAction(UPDATE_USER_DISABLED_REQUEST)

export default {
  loadUsers,
  updateUserDisabled,

  loadUsersRequest,
  updateUserDisabledRequest
}
