import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push as pushHistory } from 'react-router-redux'
import { Map } from 'immutable'

import * as Routes from 'constants/Routes'
import * as UserUtils from 'utils/user'

import { getUser, getIsLoggedIn, getIsLoaded as getIsUserLoaded } from 'common/selectors/user'

export default function requireAdmin(Component) {
  class Authenticated extends React.Component {
    static propTypes = {
      user: PropTypes.instanceOf(Map),
      location: PropTypes.object,
      isLoggedIn: PropTypes.bool.isRequired,
      isUserLoaded: PropTypes.bool.isRequired,
      pushHistory: PropTypes.func.isRequired
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps(nextProps) {
      this.checkAuth(nextProps.isLoggedIn)
    }

    // eslint-disable-next-line react/no-deprecated
    componentWillMount() {
      this.checkAuth(this.props.isLoggedIn)
    }

    checkAuth(isLoggedIn) {
      if (!isLoggedIn || !UserUtils.getIsAdmin(this.props.user)) {
        this.props.pushHistory(Routes.LOGIN)
      }
    }

    render() {
      return this.props.isUserLoaded && this.props.isLoggedIn && <Component {...this.props} />
    }
  }

  const mapStateToProps = state => ({
    user: getUser(state),
    isLoggedIn: getIsLoggedIn(state),
    isUserLoaded: getIsUserLoaded(state)
  })

  const mapActionsToProps = {
    pushHistory
  }

  return connect(mapStateToProps, mapActionsToProps)(Authenticated)
}
