import { programLine } from 'icons'
import styles from './ProgramView.module.scss'
import cx from 'classnames'

export const ProgramView = ({ programModes, programViewClassName }) => {
  const getRectangleColor = value => {
    switch (value) {
      case 1:
        return '#B3B3B3'
      case 2:
        return '#C2000B'
      case 3:
        return '#991014'
      case 4:
        return '#700310'
      default:
        return null
    }
  }

  const getRectangleHeight = value => {
    if (value === 0) return 0
    return 25 + value * 5
  }

  return (
    <div className={cx(styles.programView, programViewClassName)}>
      {programModes.map((value, i) => {
        return (
          <div
            key={i}
            className={styles.rectangle}
            style={{
              height: getRectangleHeight(Number(value)),
              backgroundColor: getRectangleColor(Number(value)),
              left: 1 + i * 13,
              top: 63 - getRectangleHeight(Number(value))
            }}
          >
            {Number(value)}
          </div>
        )
      })}
      <img
        className={styles.line}
        src={programLine}
        alt='programLine'
      />
    </div>
  )
}
