import { createSelector } from 'reselect'
import { getEntities, getUsers as getUserEntities } from './entities'
import { denormalize } from 'normalizr'
import * as schemas from '../../schemas'

export const getUserId = state => state.user.get('user')
export const getIsLoaded = state => state.user.get('isLoaded')
export const getIsLoggedIn = state => state.user.get('isLoggedIn')

export const getUser = createSelector([getUserEntities, getUserId], (entities, id) =>
  entities.get(String(id))
)

export const getUsersRaw = createSelector([getEntities, getUserEntities], (entities, users) => {
  // Find system 'owners' and assign them to system
  return denormalize(users, [schemas.user], entities)
})
