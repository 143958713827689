import { useState } from 'react'
import PropTypes from 'prop-types'

// -------------- Material-UI imports ---------------- //
import { Modal, Paper, Button } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

import styles from './returnDateModalContainer.module.scss'

const returnDateModal = props => {
  const { hideModal, contentText, title, translate, handleChangeTab } = props

  const [open] = useState(true)

  const StyledPaper = withStyles({
    root: {
      maxHeight: '100% !important',
      'overflow-y': 'visible',
      border: '2px solid #dddddd',
      verticalAlign: 'center !important',
      borderRadius: '32px',
      backgroundColor: '#ffffff'
    }
  })(props => <Paper {...props} />)

  return (
    <Modal
      onClose={hideModal}
      open={open}
      fullWidth
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
      scroll='body'
      BackdropProps={{
        classes: {
          root: styles.backdrop
        }
      }}
      PaperComponent={StyledPaper}
    >
      <div className={styles.flexModalContainer}>
        <div className={styles.modalContainer}>
          <div className={styles.header}>{title}</div>
          <div className={styles.content}>
            <div className={styles.contentText}>{contentText}</div>
            <div className={styles.button}>
              <Button
                onClick={handleChangeTab}
                className={styles.buttonConfirm}
              >
                {translate('returnPanel')}
              </Button>
            </div>
            <div className={styles.button}>
              <Button
                disableRipple
                onClick={hideModal}
                className={styles.buttonCancel}
              >
                {translate('exit')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

returnDateModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string
}

export default returnDateModal
